import {ILanguage} from "../../models/ILanguage";
import en from "../../locales/en/translation.json";
import ru from "../../locales/ru/translation.json";
import uk from "../../locales/uk/translation.json";
import de from "../../locales/de/translation.json";

export default {
    getDefaultLanguage() {
        const defaultLang = localStorage.getItem("APP_LANGUAGE");
        if (defaultLang) return defaultLang;
        else {
            const browserLanguage = navigator.language;
            if (/^en\b/.test(browserLanguage)) return "en";
            else if (/^de\b/.test(browserLanguage)) return "de";
            else return "uk";
        }
    },

    getResources(languages: ILanguage []) {
        const resources: any = {};
        languages.forEach(item => {
            if (item.lang === 'en') resources.en = {translation: en};
            else if (item.lang === 'ru') resources.ru = {translation: ru};
            else if (item.lang === 'uk') resources.uk = {translation: uk};
            else resources.de = {translation: de};
        });
        return resources;
    },

    checkBrowserLanguage() {
        const defaultLang = localStorage.getItem("APP_LANGUAGE");
        if (defaultLang) {
            if (defaultLang === "en") return {en: {translation: en}};
            else if (defaultLang === "de") return {de: {translation: de}};
            else return {uk: {translation: uk}};
        } else {
            const browserLanguage = navigator.language;
            if (/^en\b/.test(browserLanguage)) return {en: {translation: en}};
            else if (/^de\b/.test(browserLanguage)) return {de: {translation: de}};
            else return {uk: {translation: uk}};
        }
    }
}