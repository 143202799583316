import React, { useEffect, useState } from "react";
import { ViewPortContext } from "../contexts/ViewPortContext";

const getViewPortByWindowDimensions = () => {
    const { innerWidth: width } = window;
    return width < 1024;
};

const ViewPortProvider = (p: { children: any }) => {
    const [isMobile, setIsMobile] = useState(getViewPortByWindowDimensions);

    useEffect(() => {
        function handleResize() {
            setIsMobile(getViewPortByWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ViewPortContext.Provider value={{ isMobile }}>
            {p.children}
        </ViewPortContext.Provider>
    );
};

export default ViewPortProvider;