import ActionTypes from "./constants";
import { ContainerState, ContainerActions } from "./types";
import { Device } from "../../../../models/Device";

export const initialState: ContainerState = {
    listDevice: [],
    listDevicesBrands: [],
    deviceUniqueId: undefined,
    deviceInfo: undefined,
    deviceId: undefined,
    device_CRUD_Mode: undefined,
    device: undefined,
};

function settingsDevicesReducer(
    state: ContainerState = initialState,
    action: ContainerActions,
): ContainerState {
    switch (action.type) {
        case ActionTypes.UPDATE_LIST_DEVICE:
            return {
                ...state,
                listDevice: action.payload,
            };
        case ActionTypes.UPDATE_DEVICES_BRANDS:
            return {
                ...state,
                listDevicesBrands: action.payload,
            };
        case ActionTypes.SET_DEVICE_UNIQUE_ID:
            return {
                ...state,
                deviceUniqueId: action.payload,
            };
        case ActionTypes.SET_DEVICE_INFO:
            return {
                ...state,
                deviceInfo: action.payload,
            };
        case ActionTypes.SET_DEVICE_ID:
            return {
                ...state,
                deviceId: action.payload,
                device_CRUD_Mode: "device.view",
            };
        case ActionTypes.UPDATE_DEVICE:
            return {
                ...state,
                device: action.payload,
            };
        case ActionTypes.SET_DEVICE_CRUD_MODE:
            return {
                ...state,
                device_CRUD_Mode: action.payload,
            };
        case ActionTypes.UPDATE_DEVICE_BY_ATTRIBUTE: {
            const { deviceKey, deviceVal, deviceAttr } = action.payload;
            const { device } = state;
            if (deviceAttr && (deviceAttr === "apnSIM1" || deviceAttr === "loginSIM1" ||
                deviceAttr === "passwordSIM1")) {
                if (!device.sim1Attributes) device.sim1Attributes = {};
                if (deviceVal) device.sim1Attributes[deviceKey as keyof any] = deviceVal;
                else delete device.sim1Attributes[deviceKey as keyof any];
            } else if (deviceAttr && (deviceAttr === "apnSIM2" || deviceAttr === "loginSIM2" ||
                deviceAttr === "passwordSIM2")) {
                if (!device.sim2Attributes) device.sim2Attributes = {};
                if (deviceVal) device.sim2Attributes[deviceKey as keyof any] = deviceVal;
                else delete device.sim2Attributes[deviceKey as keyof any];
            } else if (deviceKey === "interval" || deviceKey === "distance" || deviceKey === "angle") {
                if (!device.attributes) device.attributes = {};
                if (!device.attributes.tracking) device.attributes.tracking = {};
                if (deviceVal) device.attributes.tracking[deviceKey as keyof any] = deviceVal;
                else delete device.attributes.tracking[deviceKey as keyof any];
            } else {
                device[deviceKey as keyof Device] = deviceVal;
            }
            return {
                ...state,
                device: { ...device },
            };
        }
        default:
            return state;
    }
}

export default settingsDevicesReducer;