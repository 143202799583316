import { combineReducers } from 'redux';
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import languageProviderReducer from '../../modules/LanguageProvider/reducer';
import settingsReducer from '../../modules/SettingsModule/StateManagement/Shared/reducer';
import settingsObjectsReducer from '../../modules/SettingsModule/StateManagement/Objects/reducer';
import settingsDevicesReducer from '../../modules/SettingsModule/StateManagement/Devices/reducer';
import { history } from './history';
import sharedReducer from "../../shared/reducer";
import reportsReducer from "../../modules/ReportsModule/StateManagement/reducer"

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
    const appReducer = combineReducers({
        router: createRouterReducer(history),
        shared: sharedReducer,
        language: languageProviderReducer,
        settings: settingsReducer,
        settingsObjects: settingsObjectsReducer,
        settingsDevices: settingsDevicesReducer,
        reports: reportsReducer,
        ...injectedReducers,
    });

    const rootReducer = (state: any, action: any) => {
        if (action.type === 'shared/USER_LOGOUT') {
            return appReducer(undefined, action)
        }

        return appReducer(state, action)
    };

    return rootReducer;
}